<template>
    <modal ref="materialState" 
    :titulo="`${material.estado === 0 ? 'Activar' : 'Inactivar' } material`" 
    :adicional="`${material.estado === 0 ? 'Activar' : 'Inactivar' }`"  
    @adicional="changeMaterialState">
        <div class="row m-3 f-15">
            <p class="col-12 text-center">¿Desea {{ material.estado === 0 ? 'Activar' : 'Inactivar' }} este material?</p>
        </div>
    </modal>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed:{
        ...mapGetters({
            material: 'almacen/materiales/material'
        })
    },
    methods: {
        toggle(){
            this.$refs.materialState.toggle();
        },
        async changeMaterialState(){
            await this.$store.dispatch('almacen/materiales/Action_change_material_state', this.material.id);
            this.toggle();
        }
    }
}
</script>

<style lang="css" scoped>
</style>
