<template>
    <section class="materiales-ver-informacion">
        <navbar-admin>
            <p class="f-12">Material {{ material.estado === 0 ? 'inactivo' : 'activo' }}</p>
            <el-tooltip placement="bottom" effect="light" visible-arrow>
                <template #content>
                    <p> {{ material.estado === 0 ? 'Activar' : 'Inactivar' }} material</p>
                </template>
                <button
					class="btn wh-32 f-22 d-flex d-middle-center ml-2"
                    :class="material.estado === 0 ? 'btn-estado' : 'btn-estado-activo'"
                    @click="functionPermitAction('almacen.materiales.estado', beforeChangeMaterialState)"
                >
                    <i class="icon-package " />
                </button>
            </el-tooltip>
            <el-tooltip content="Editar" placement="bottom" effect="light" visible-arrow>
                <button class="btn btn-square32-5d ml-2"
                    :class="permitAction('almacen.materiales.editar') ? 'cr-pointer' : 'cr-not-allowed'" 
                    @click="functionPermitAction('almacen.materiales.editar', beforeUpdateMaterial)"
                >
                    <i class="icon-pencil-outline text-white" />
                </button>
            </el-tooltip>
            <el-tooltip content="Eliminar" placement="bottom-end" effect="light" visible-arrow>
                <button class="btn btn-square32-5d ml-2"
                    :class="permitAction('almacen.materiales.eliminar') ? 'cr-pointer' : 'cr-not-allowed'" 
                    @click="functionPermitAction('almacen.materiales.eliminar', openModalDeleteMaterial)"
                >
                    <i class="icon-trash-can-outline text-white" />
                </button>
            </el-tooltip>
        </navbar-admin>
        <tabs :tabs="tabsAlmacenInfo" />
        <router-view />
        <!-- partials  -->
        <modal-eliminar 
        ref="modalDeleteMaterial" 
        title="Eliminar material" 
        mensaje="¿Está seguro que quiere eliminar este material?" 
        @delete="beforeDeleteMaterial"
        />
        <modal-cambiar-estado-material ref="modalChangeMaterialState" />
    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import modalCambiarEstadoMaterial from './partials/modalCambiarEstadoMaterial'
export default {
    components:{
        modalCambiarEstadoMaterial,
    },
    computed:{
        ...mapGetters({
			user: 'auth/user',
            material: 'almacen/materiales/material'
        })
    },
    watch:{
        "$route.name"(val){
            this.replaceBreadcumb({ position: 3, breadcumb: val})
        }
    },
    async created(){
        await this.$store.dispatch('almacen/materiales/Action_get_material', this.$route.params.id);
        this.$store.dispatch('navigation/breadcumbs/getBreadcumbs', [ 'almacen.main', 'almacen.materiales']);
        this.$store.dispatch('navigation/breadcumbs/spliceBreadcumbs',
            [
                {
                    position: 2,
                    breadcumb: {
                        name: this.material.nombre,
                        route: 'almacen.materiales.ver.datos',
                        params: {id: this.material.id}
                    }
                }
            ]
        );
        this.replaceBreadcumb({ position: 3,  breadcumb: this.$route.name })
    },
    data(){
        return{
            tabsAlmacenInfo: [
                {
                    titulo:'Información',
                    ruta:"almacen.materiales.ver.datos",
                },
                {
                    titulo:'Stock',
                    ruta:"almacen.materiales.ver.stock",
                },
                {
                    titulo:'Cotizaciones',
                    ruta:"almacen.materiales.ver.cotizaciones",
                },
                {
                    titulo:'Compras',
                    ruta:"almacen.materiales.ver.compras",
                },
                {
                    titulo:'Mantenimiento',
                    ruta:"almacen.materiales.ver.mantenimiento",
                },
            ],
        }
    },
    methods:{
        ...mapActions({
            deleteMaterial: 'almacen/materiales/Action_delete_material',
            replaceBreadcumb: 'navigation/breadcumbs/replaceBreadcumb'
        }),
        async beforeDeleteMaterial(){
            const deleted = await this.deleteMaterial(this.material.id);
            if(deleted) this.$router.push({name: 'almacen.materiales'})
        },
        beforeChangeMaterialState(){
            this.$refs.modalChangeMaterialState.toggle()
        },
        beforeUpdateMaterial(){
            this.$router.push({ name: 'almacen.materiales.editar', params: { id: this.material.id } })
        },
        openModalDeleteMaterial(){
            this.$refs.modalDeleteMaterial.toggle();
        },
    }
}
</script>

<style lang="scss" scoped>
.materiales-ver-informacion{
    background: white;
}
.btn-estado{
    background: #f1f2f4;
    color: #5d5d5d;
    &-activo{
        background: #3CD273;
        color: white;
    }
}
</style>